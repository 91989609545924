<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.attendance') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="attendance" :class="'btn btn-success text-light'">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <!-- <b-row>
                      <b-col lg="6" sm="6">
                        <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_memo_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row> -->
                    <b-overlay :show="load">
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.office_id"
                                  :options="officeList"
                                  id="office_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                              >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_category_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_category_id"
                                :options="trainingCategoryList"
                                id="training_category_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="training_title_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="search.training_title_id"
                                :options="trainingTitleList"
                                id="training_title_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="search.circular_memo_no"
                                  :options="circularList"
                                  id="circular_memo_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Batch No" vid="other_trainee_type_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="batch_no"
                            >
                            <template v-slot:label>
                              {{$t('elearning_config.trainee_type')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                                plain
                                v-model="search.other_trainee_type_id"
                                :options="traineeTypeList"
                                id="other_trainee_type_id"
                                >
                                <template v-slot:first>
                                     <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-overlay :show="loading">
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('elearning_tpm.training_attendence_sheet') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col v-for="(info, index) in dateList" :key="index">
                                          <b-form-checkbox
                                            :id="'checkbox-' + info.text"
                                            v-model="info.checked"
                                            :name="'checkbox-' + info.text"
                                            value=2
                                            unchecked-value=1
                                            @change="checkCount(info)"
                                          >
                                          {{info.text | dateFormat}}
                                          </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col>
                                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="16" :office-id="datas[0].office_id">
                                                <template v-slot:projectNameSlot>
                                                {{ }}
                                                </template>
                                                {{ $t('elearning_tpm.training_attendence_sheet') }}
                                            </list-report-head>
                                        </b-col>
                                    </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <b-row>
                                          <b-col>
                                            <table style="width:100%;color:black;">
                                              <tr v-if="search.circular_memo_no">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                              </tr>
                                              <tr v-if="search.org && ($store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12)">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_bn : search.org }}</td>
                                              </tr>
                                              <tr v-if="search.ofc_name">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.office') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.ofc_name_bn : search.ofc_name }}</td>
                                              </tr>
                                              <tr v-if="search.training_title">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                              </tr>
                                               <tr v-if="search.training_end_date">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.training_end_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.training_end_date | dateFormat }}</td>
                                              </tr>
                                            </table>
                                          </b-col>
                                          <b-col>
                                            <table style="width:100%;color:black;">
                                              <tr v-if="search.fiscal_year">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                              </tr>
                                              <tr v-if="search.ofc_type_name">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.office_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.ofc_type_name_bn : search.ofc_type_name }}</td>
                                              </tr>
                                              <tr v-if="search.training_category">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                              </tr>
                                              <tr v-if="search.training_start_date">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.training_start_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.training_start_date | dateFormat }}</td>
                                              </tr>
                                            </table>
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="dateList.filter(item1 => item1.checked === '2').length>0">
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.trainee_name') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.mobile_no') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.father_name') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_accommodation.address') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.age') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.monthly_income') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.edu_quali') }}</b-th>
                                            <b-th style="width:10%" class="text-center" colspan="3">{{ $t('elearning_tpm.sig_date') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                            <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                              <b-th  style="width:10%" class="text-center">{{ field.text | dateFormat }}</b-th>
                                            </slot>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale == 'bn' ? '০': '0') + $n(info.mobile, { useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_of_father_husband_bn : info.name_of_father_husband }}</b-td>
                                          <b-td class="text-center">{{ info.other_address }}</b-td>
                                          <b-td class="text-center">{{ $n(info.age) }}</b-td>
                                          <b-td class="text-center">{{ info.monthly_income }}</b-td>
                                          <b-td class="text-center">{{ info.name_of_highest_degree }}</b-td>
                                          <slot v-for="(field, index1) in dateList.filter(item1 => item1.checked === '2')">
                                            <b-td style="width:10%" class="text-center" :id="'text' + field" :key="index1"></b-td>
                                          </slot>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{dateList}}</pre> -->
        </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceAllList, circularPublicationBirtan, attendenceSheetList, personalDataList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import exportPdf from './export_pdf_list'

export default {
  props: ['id'],
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.personalDataList()
    this.search = Object.assign({}, this.search, {
      fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
    })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
      this.search.office_type_id = this.$store.state.Auth.authUser.office_type_id
      this.search.office_id = this.$store.state.Auth.authUser.office_id
      this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
      this.officeList = this.getOfficeList(this.$store.state.Auth.authUser.office_type_id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      showData: true,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      search: {
        circular_memo_no: 0,
        org_id: 0,
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        fiscal_year_id: 0,
        registration_for: 2,
        other_trainee_type_id: 0,
        training_start_date: '',
        training_end_date: '',
        office_type_id: 0,
        office_id: 0
      },
      formData: {
        id: '',
        circular_memo_no: 0,
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        batch_no: 0,
        other_trainee_type_id: 0,
        training_date: '',
        trainer_id: 0,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      datas: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      officeList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      allBatchListData: [],
      trainingTypeList: [],
      officeTypeList: [],
      batchList: [],
      traineeTypeList: [],
      circularWiseAttendanceList: [],
      otherTraineeTypeList: [],
      detailErrors: [],
      approvedDate: '',
      dateList: [],
      personalInfoList: []
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'search.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.trainingTypeList = this.getTypeList(newValue)
        if (this.$store.state.Auth.activeRoleId === 1) {
          this.officeTypeList = this.getOfficeTypeList(newValue)
        }
      } else {
        this.trainingTypeList = []
      }
    },
    'search.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.training_title_id': function (newValue) {
      this.getCircularList()
    },
    'search.circular_memo_no': function (newValue) {
      this.getOtherTraineeType()
    },
    'search.other_trainee_type_id': function (newVal, oldVal) {
        this.getCircularMemoNo()
    }
  },
  methods: {
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    checkCount (item) {
      const total = this.dateList.filter(item1 => item1.checked === '2')
      if (total.length > 3) {
        this.$toast.error({
          title: 'Error',
          message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ তিনটি তারিখ যোগ করা যাবে' : 'Maximum three dates can be added!'
        })
        item.checked = false
      }
    },
    async getCircularMemoNo () {
      this.load = true
        const params = {
          circular_memo_no: this.search.circular_memo_no,
          table: 'iab_circular_publications'
        }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationBirtan, params)
      if (!result.success) {
        this.datas = []
      } else {
        this.lcErrorMsg = ''
          const trData = result.data
          this.search.training_start_date = trData.training_start_date
          this.search.training_end_date = trData.training_end_date
        const listData = result.aryRange.map(item => {
          const updateData = {}
          updateData.checked = false
          updateData.text = item
          return Object.assign({}, updateData)
        })
        this.dateList = listData
        this.searchData()
      }
      this.load = false
    },
    async personalDataList () {
        const params = {
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, personalDataList, params)
        if (result.success) {
            this.personalInfoList = result.data
        } else {
            this.personalInfoList = []
        }
        this.searchData()
    },
    async searchData () {
      this.loadData()
    },
    loadData () {
        this.loading = true
        this.getCustomDataSearch()
        RestApi.getData(trainingElearningServiceBaseUrl, attendenceSheetList, this.search).then(response => {
          if (response.success) {
            this.datas = response.data
          }
          this.loading = false
        })
    },
    getCustomDataSearch () {
      const orgObj = this.$store.state.commonObj.organizationProfileList.find(organizationProfileList => organizationProfileList.value === parseInt(this.search.org_id))
      if (typeof orgObj !== 'undefined') {
        this.search.org = orgObj.text_en
        this.search.org_bn = orgObj.text_bn
      } else {
        this.search.org = ''
        this.search.org_bn = ''
      }
      const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
      if (typeof fiscalYearObj !== 'undefined') {
        this.search.fiscal_year = fiscalYearObj.text_en
        this.search.fiscal_year_bn = fiscalYearObj.text_bn
      //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
      } else {
        this.search.fiscal_year = ''
        this.search.fiscal_year_bn = ''
      }
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_title_id))
      if (typeof trainingTitleObj !== 'undefined') {
        this.search.training_title = trainingTitleObj.text_en
        this.search.training_title_bn = trainingTitleObj.text_bn
      } else {
        this.search.training_title = ''
        this.search.training_title_bn = ''
      }
      const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
      if (typeof trainingTypeObj !== 'undefined') {
        this.search.training_type = trainingTypeObj.text_en
        this.search.training_type_bn = trainingTypeObj.text_bn
      } else {
        this.search.training_type = ''
        this.search.training_type_bn = ''
      }
      const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_category_id))
      if (typeof trainingCategoryObj !== 'undefined') {
        this.search.training_category = trainingCategoryObj.text_en
        this.search.training_category_bn = trainingCategoryObj.text_bn
      } else {
        this.search.training_category = ''
        this.search.training_category_bn = ''
      }
      const trainerObj = this.personalInfoList.find(personalInfoList => personalInfoList.value === parseInt(this.search.trainer_id))
      if (typeof trainerObj !== 'undefined') {
        this.search.trainer_name = trainerObj.text_en
        this.search.trainer_name_bn = trainerObj.text_bn
      } else {
        this.search.trainer_name = ''
        this.search.trainer_name_bn = ''
      }
      const ofcTypeObj = this.$store.state.commonObj.officeTypeList.find(ofcTypeList => ofcTypeList.value === parseInt(this.search.office_type_id))
      if (typeof ofcTypeObj !== 'undefined') {
        this.search.ofc_type_name = ofcTypeObj.text_en
        this.search.ofc_type_name_bn = ofcTypeObj.text_bn
      } else {
        this.search.ofc_type_name = ''
        this.search.ofc_type_name_bn = ''
      }
      const ofcObj = this.$store.state.commonObj.officeList.find(ofcList => ofcList.value === parseInt(this.search.office_id))
      if (typeof ofcObj !== 'undefined') {
        this.search.ofc_name = ofcObj.text_en
        this.search.ofc_name_bn = ofcObj.text_bn
      } else {
        this.search.ofc_name = ''
        this.search.ofc_name_bn = ''
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.training_attendence_sheet')
      exportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.datas, this.search, this.dateList.filter(item1 => item1.checked === '2'))
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getOfficeTypeOfficerList (officeTypeId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return OfficeTypeList.filter(item => item.value === officeTypeId)
      }
      return OfficeTypeList
    },
    getOfficeName (id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    getOrgName (id) {
      const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    getTraineeType () {
      const typeData = this.otherTraineeTypeList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.other_trainee_type_bn }
        } else {
          return { value: obj.id, text: obj.other_trainee_type }
        }
      })
      this.traineeTypeList = typeData
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        org_id: this.search.org_id,
        fiscal_year_id: this.search.fiscal_year_id,
        training_type_id: this.search.training_type_id,
        training_category_id: this.search.training_category_id,
        office_id: this.search.office_id,
        training_title_id: this.search.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/circular-publication-list' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.circularList = []
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
    async getOtherTraineeType () {
      this.circularLoading = true
      const serchData = {
        org_id: this.search.org_id,
        fiscal_year_id: this.search.fiscal_year_id,
        office_id: this.search.office_id,
        office_type_id: this.search.office_type_id,
        circular_memo_no: this.search.circular_memo_no
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/iabm/circular-publications/other-trainee-type' + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
        this.otherTraineeTypeList = []
      } else {
        this.otherTraineeTypeList = result.otherTraineeTypeList
        this.getTraineeType()
      }
      this.circularLoading = false
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async getAttedenceList () {
      if (this.formData.circular_memo_no && this.formData.batch_no) {
        this.loading = true
        const params = {
          circular_memo_no: this.formData.circular_memo_no,
          other_trainee_type_id: this.formData.other_trainee_type_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, attendanceAllList, params)
        if (result.success) {
          this.circularWiseAttendanceList = result.circularWiseAttendance
          const listData = result.aryRange.map(item => {
            const updateData = {}
            updateData.checked = false
            updateData.morning = false
            updateData.day = false
            updateData.text = item
            updateData.approved = 0
            return Object.assign({}, updateData)
          })
          this.dateList = listData
          if (result.attendenceDate) {
            this.dateList = result.attendenceDate
          }
          const datas = result.data.map(item => {
            var dataEvent = []
            const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (typeof desigObj !== 'undefined') {
              desigData.designation_name = desigObj.text_en
              desigData.designation_name_bn = desigObj.text_bn
            } else {
              desigData.designation_name = ''
              desigData.designation_name_bn = ''
            }
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_data_name = officeObj.text_en
              officeData.office_data_name_bn = officeObj.text_bn
            } else {
              officeData.office_data_name = ''
              officeData.office_data_name_bn = ''
            }
            this.dateList.forEach((dateList) => {
              const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
              const items = {}
              items.training_application_id = item.id
              items.attendence_status = false
              if (typeof saveObj !== 'undefined') {
                if (saveObj.status === 1) {
                  items.attendence_status = true
                }
                items.morning = saveObj.morning
                items.day = saveObj.day
                items.text = dateList.text
              } else {
                items.morning = false
                items.day = false
                items.text = dateList.text
              }
              dataEvent.push(items)
            })
            const newData = {
                dateList: dataEvent
            }
            return Object.assign({}, item, desigData, newData, orgData, officeData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = {}
          this.loading = false
        }
      }
    },
    checkData (item, type) {
      this.details.forEach((detail, detailIndex) => {
        detail.dateList.forEach((dateList, dateListIndex) => {
          if (dateList.text === item.text) {
            if (type === 'morning') {
              if (item.morning === '2') {
                dateList.morning = true
              } else {
              dateList.morning = false
              }
            } else {
              if (item.day === '2') {
                dateList.day = true
              } else {
              dateList.day = false
              }
            }
          }
        })
      })
    }
  }
}
</script>
